import NewPredictionCmp from './components/new-prediction/new-prediction.cmp.vue';
import ExistingPredictionCmp from './components/existing-prediction/existing-prediction.cmp.vue';


export default {
    name: 'PredictionAdd',
    data() {
        return {
            model: 'tab-1'
        }
    },

    components: {
        NewPredictionCmp,
        ExistingPredictionCmp
    }
}
