import imageInput from '@/mixins/image-input.js';
import { mapState } from 'vuex';
import MatchModel from '../../../predictions/models/match.model';
import PredictionsPostModel from '../../../predictions/models/predictions-post.model';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
    name: 'NewPrediction',
    data() {
        return {
            item: {},
            
            match: '',
            dtp1: false,
            dtp3: false,
            dtp4: false,
            dtp2: false,
        }
    }, components: {
        quillEditor
    },
    methods: {
        changeFirstTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch('getFileUrl', formData).then(res => {
                this.item.firstLogo = res;
            })
        },
        changeSecondTeamLogo(e) {
            this.resfresh = false;
            let formData = new FormData();
            formData.append('Photo', e);
            this.$store.dispatch('getFileUrl', formData).then(res => {
                this.item.secondLogo = res;
            })
        },

        approve() {
            let data = new PredictionsPostModel({ ...this.item, matchId: this.match });
            let formData = new FormData();
            for (let key in data) {
                if (data[key]) {
                    formData.append(key, data[key]);
                }
            }

            this.$store.dispatch('predictions/setItem', formData).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                    this.match = '';
                }
            });

        },

        getSelectedMatch(e) {
            let matches = this.$store.state.predictions.matches;
            let selectedMatch = matches.filter(i => {
                return i.id === e;
            });
            this.item = new MatchModel(selectedMatch[0])

        },
    },
    computed: {
        ...mapState({
            tournaments: (state) => state.tournaments.items,
            games: (state) => state.games.items,
            matches: state => state.predictions.matches,
            categories: state => state.categories.items,
        }),
        firstItemLogo() {
            const logo = this.item?.firstLogo?.toString();
            if (this.item?.firstLogo && this.item?.firstLogo.length) {
                if (logo?.includes('http')) return logo;
                else return 'https://' + this.item.firstLogo;
            }
            return null;
        },
        secondItemLogo() {
            const logo = this.item?.secondLogo?.toString();
            if (this.item?.secondLogo && this.item?.secondLogo.length) {
                if (logo?.includes('http')) return logo;
                else return 'https://' + this.item.secondLogo;
            }
            return null;

        }
    },
    mixins: [imageInput],
}
